import React, { createRef } from 'react';
import { Button } from 'antd';
import Label from '../../elements/Label';

import BetterLogo from '../../assets/images/BetterLogoR.png';

import { useWindowWidth } from '../../contexts/WindowWidthContext';
import TermsModal from '../../Modals/Terms'
import AboutModal from '../../Modals/About';

export default function DesktopAppBar() {
    const termsRef = createRef();
    const aboutRef = createRef();
    const windowWidth = useWindowWidth();

    return (
        <div className='toolbar'>
          <div className='logo-col'>
              <img src={BetterLogo} alt='The Better App' className='logo' />
          </div>
          <div className='header-col'>
            <div className='flex-wrapper'>
              <Label labelType={windowWidth > 1200 ? "H3" : "H4"} caption="Community Programs" className='page-title' />
              <div className='header-buttons'>
                  <Button type='link' className='header-button' onClick={() => aboutRef?.current?.toggleOpen(true)} >Learn more about us</Button>
                  <Button type='link' className='header-button' onClick={() => termsRef?.current?.toggleOpen(true)} >Terms of use</Button>
              </div>
            </div>
          </div>
          <AboutModal ref={aboutRef}/>
          <TermsModal ref={termsRef}/>
        </div>
    );
}