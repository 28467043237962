import moment from 'moment';
import { isNil } from 'lodash';

const startDateFormat = '0001-01-01';


export const isValidDate = value =>
  !isNil(value) && !value.toString().startsWith(startDateFormat) && moment(value).isValid();

export const getMoment = (value, defaultValue = null) => {
  return isValidDate(value) ? moment(value) : defaultValue;
};

export const getDate = (value, defaultValue = null) => {
  return isValidDate(value) ? moment(value).toDate() : defaultValue;
};
