import { Button, Col, Modal, Row } from 'antd';
import React, { useImperativeHandle, forwardRef, useState } from 'react';
import Label from '../../elements/Label';

import BWSLogo from '../../assets/images/BWSLogo.png';
import LVBLogo from '../../assets/images/LVBLogo.jpg';

export default forwardRef((_, ref) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleOpen = (open) => {
    if(modalOpen !== open) {
      setModalOpen(open);
    }
  };

  useImperativeHandle(ref, () => ({
    toggleOpen,
  }));

  return (
    <Modal
            title='About Us'
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            onCancel={() => setModalOpen(false)}
            width={'768px'}
            bodyStyle={{ padding: '16px', zIndex: 990 }}
            style={{ zIndex: 1000 }}
            footer={false}
            centered
        >
            <Label labelType="Body2" caption='The Better Community website was developed by Better World Software Inc. and the Langley Volunteer Bureau to help local non-profit organizations connect with volunteers in the community.' /><br />

            <Label labelType="Body2" caption='We will be regularly updating and improving our website based on your feedback.  Let us know what we could do to make your experience better, by emailing us at: ' style={{ paddingTop: '24px' }} /><br />
            <a href='mailTo:admin@betterworldsoftware.ca'>admin@betterworldsoftware.ca</a><br /><br />

            <Row style={{ marginBottom: '16px' }}>
                <Col span={6} style={{ textAlign: 'center' }}>
                    <a href='https://betterworldsoftware.ca' target="_blank" rel="noopener noreferrer" > <img src={BWSLogo} alt='Better World Software Inc.' style={{ width: '100%', padding: '8px', marginTop: '16px' }} /></a>
                </Col>
                <Col span={6} style={{ textAlign: 'center' }}>
                    <a href='https://www.langleyvolunteers.ca' target="_blank" rel="noopener noreferrer" > <img src={LVBLogo} alt='Langley Volunteer Bureau' style={{ width: '60%', padding: '16px', marginTop: '8px' }} /></a><br />
                    <Label labelType="Subtitle1" caption='Langley Volunteer Bureau' style={{ padding: '4px 8px' }} />
                </Col>

            </Row>

            <Label labelType="Body1" caption='Non-profits hosting events or providing important services to the community, need the support of volunteers like you to be successful!' /><br />
            <br />
            <Row>
                <Button type='primary' style={{ marginTop: '16px', float: 'right' }} onClick={() => setModalOpen(false)} >Close</Button>
            </Row>
        </Modal>
  );
})