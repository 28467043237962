import { Modal, notification } from 'antd';
import React, { useImperativeHandle, forwardRef, useState } from 'react';
import { Form } from 'antd';
import axios from 'axios';
import { baseUrl } from '../../api';
import { getConfig } from '../../helpers/api';
import FormBuilder from '../../components/FormBuilder';
import * as options from '../../options';
import { useWindowWidth } from '../../contexts/WindowWidthContext';
import {
  setDefaults,
  geocode,
  RequestType,
} from "react-geocode";

export default forwardRef((_, ref) => {
  const GOOGLE_API_KEY = 'AIzaSyBR9_5fTQ5c_2YRTJVFowV9JkkOXSsEr4I';
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState({});
  const windowWidth = useWindowWidth();
  const [selfRegistered, setSelfRegistered] = useState(true);
  const [preferredContact, setPreferredContact] = useState('');
  const [preferredContact2, setPreferredContact2] = useState('');
  const [form] = Form.useForm();


  const toggleOpen = (open, data = null) => {
    // Update only when value changed
    if (modalOpen !== open) {
      // When closed clear fields
      if (!open) {
        form.resetFields();
        setData({});
        setSelfRegistered(true);
      }
      if (data) {
        setData(data);
      }
      setModalOpen(open);
    }
  };

  // Expose these methods in ref so they can be called by other components
  useImperativeHandle(ref, () => ({
    toggleOpen,
  }));


  const handleSubmit = async formData => {
    try {
      notification?.destroy();

      function validateEmail(emailAddress) {
        const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        const isValidEmail = regex.test(emailAddress.trim());
        return (isValidEmail);
      };

      if (!formData.acceptedDisclaimer) {
        notification.error({
          message: 'You must accept the disclaimer.', placement: window.innerWidth < 768 ? 'bottomLeft' : 'topRight', duration: null
        });
        return;
      }

      if (!formData.isSenior && !formData.isDisabled) {
        notification.error({
          message: 'You must indicate you have a disability or are a senior.', placement: window.innerWidth < 768 ? 'bottomLeft' : 'topRight', duration: null
        });
        return;
      }

      if (formData.residentEmail) {
        if (formData.residentEmail.trim() !== '') {
          if (validateEmail(formData.residentEmail.trim()) === false) {
            notification.error({
              message: 'You must enter a valid email address.', placement: window.innerWidth < 768 ? 'bottomLeft' : 'topRight', duration: null
            });
            return;
          }
        }
      }

   
      const signDate = new Date();

    
      // get Lat and Lng
      let googleAddress = formData.streetAddress + ',' + formData.city + ',BC,CANADA,' + formData.postalCode;
      let lat = null;
      let lng = null;

      setDefaults({
        key: GOOGLE_API_KEY, // Your API key here.
        language: "en", // Default language for responses.
      });

   
      await geocode(RequestType.ADDRESS, googleAddress).then(response => {
        let types = response.results[0].types[0];
        if (types !== 'premise' && types !== 'street_address') {
          notification.error({
            message: 'We cannot locate your address on google maps.  Please check your address again.', placement: window.innerWidth < 768 ? 'bottomLeft' : 'topRight', duration: null
          });
          return;
        }
        else {
          lat = response.results[0].geometry.location.lat;
          lng = response.results[0].geometry.location.lng;
        }
      });

    
      if (lat !== null) {

        let resident = {
          companyId: data.companyId,
          eventId: data.id,
          isSenior: formData.isSenior,
          isDisabled: formData.isDisabled,
          residentType: formData.residentType,
          residentName: formData.residentName,
          residentPhone: formData.residentPhone,
          residentEmail: formData.residentEmail,
          residentPrefContactMethod: formData.residentPrefContactMethod,

          selfRegistered: !formData.registeringOnBehalf,
          contactName: formData.contactName,
          contactPhone: formData.contactPhone,
          contactEmail: formData.contactEmail,
          contactPrefContactMethod: formData.contactPrefContactMethod,

          buildingName: formData.buildingName,
          unitNum: formData.unitNum,
          streetAddress: formData.streetAddress,
          addressLine2: formData.addressLine2,
          city: formData.city,
          region: formData.region,
          postalCode: formData.postalCode,

          lat: lat,
          lng: lng,
          acceptedDisclaimer: signDate

        };

        console.log('resident data', resident);

        const response = await axios.post(`${baseUrl}/website/PostProgramRegister`, resident, getConfig());

        if (response) {
          notification.success({ message: 'Successfully Registered', placement: window.innerWidth < 768 ? 'bottomLeft' : 'topRight', duration: null });
          toggleOpen(false);
        }
        else {
          notification.error({
            message: 'Sorry something went wrong. Please try again later.', placement: window.innerWidth < 768 ? 'bottomLeft' : 'topRight', duration: null
          });
        }
      }
    } catch (e) {
      notification.error({
        message: 'Sorry something went wrong. Please try again later.', placement: window.innerWidth < 768 ? 'bottomLeft' : 'topRight', duration: null
      });
    }
  }

  const handleFormValueChanged = (name, value) => {
    if (name === 'registeringOnBehalf') {
      setSelfRegistered(!value);
    } else if (name === 'residentPrefContactMethod') {
      setPreferredContact(value);
    }
    else if (name === 'contactPrefContactMethod') {
      setPreferredContact2(value);
    }
  }

  const checkConfig = windowWidth > 768 ? [{ colWidth: 8 }, { colWidth: 10 }] :
    windowWidth > 580 ? [{ colWidth: 14 }, { colWidth: 10 }] :
      [{ colWidth: 14 }, { colWidth: 14 }];

  const contactConf = windowWidth > 768 ? [{ colWidth: 8 }, { colWidth: 10 }, { colWidth: 6 }] :
    windowWidth > 580 ? [{ colWidth: 14 }, { colWidth: 14, newLine: true }, { colWidth: 8, colOffset: 2 }] :
      [{ colWidth: 24 }, { colWidth: 24, newLine: true }, { colWidth: 24 }];

  const regionConf = windowWidth > 580 ? [{ colWidth: 12 }, { colWidth: 4 , colOffset: 1}, { colWidth: 6, colOffset: 1 }] :
    [{ colWidth: 16 }, { colWidth: 8 }, { colWidth: 10 }];

  const colConf5 = windowWidth > 768 ? [{ colWidth: 4 }, { colWidth: 20 }] :
    windowWidth > 580 ? [{ colWidth: 6 }, { colWidth: 18 }] :
      [{ colWidth: 6}, { colWidth: 18}];

  // const colConf6 = windowWidth > 768 ? [{ colWidth: 16 }, { colWidth: 8 }] :
  //   windowWidth > 580 ? [{ colWidth: 14 }, { colWidth: 10 }] :
  //     [{ colWidth: 24 }, { colWidth: 24 }];

  const residentName = windowWidth > 768 ? [{ colWidth: 15 }, { colWidth: 8, colOffset: 1 }] :
    windowWidth > 580 ? [{ colWidth: 14 }, { colWidth: 8, colOffset: 2 }] :
      [{ colWidth: 24 }, { colWidth: 16 }];

  const registeredByConf = windowWidth > 768 ? [{ colWidth: 16 }, { colWidth: 8, newLine: true }, { colWidth: 10 }, { colWidth: 6 }] :
    windowWidth > 580 ? [{ colWidth: 14 }, { colWidth: 10 }, { colWidth: 14 }, { colWidth: 8, colOffset: 2 }] :
      windowWidth > 480 ? [{ colWidth: 24 }, { colWidth: 14 }, { colWidth: 14, newLine: true }, { colWidth: 10 }] :
        [{ colWidth: 24 }, { colWidth: 24 }, { colWidth: 24, newLine: true }, { colWidth: 24 }];


  const registrantFields = [
    { fieldName: 'heading', controlType: 'heading', labelName: 'Resident Requesting Snow Angel', row: 1, colWidth: 24, colOffset: 0, hasEvent: false, disabled: true },

    { fieldName: 'residentName', controlType: 'input', required: true, requiredText: 'Name required', tabIndex: 1, labelName: 'Resident Name', colOffset: 0, ...residentName[0], row: 1, hasEvent: false, disabled: false },
    { fieldName: 'residentType', controlType: 'radio', required: true, requiredText: 'Owner/Renter required', allowClear: false, labelName: 'Type', colOffset: 0, ...residentName[1], options: options.recipientType, row: 1, hasEvent: true, disabled: false },

    { fieldName: 'isSenior', controlType: 'checkbox', labelName: 'I am a senior', checkboxOnOwnLine: true, row: 1, ...checkConfig[0], colOffset: 0, hasEvent: false, disabled: false },
    { fieldName: 'isDisabled', controlType: 'checkbox', labelName: 'I have a disability', checkboxOnOwnLine: true, row: 1, ...checkConfig[1], colOffset: 0, hasEvent: false, disabled: false },

    { fieldName: 'residentPrefContactMethod', tooltip: 'Snow angel coordinators will use this only.', controlType: 'radio', required: true, requiredText: 'Contact Method required', colOffset: 0, ...contactConf[0], allowClear: false, options: options.preferredContactMethod, labelName: 'Preferred Contact Method', row: 1, hasEvent: true, disabled: false },
    { fieldName: 'residentEmail', controlType: 'input', labelName: 'Email', row: 1, colOffset: 0, hasEvent: false, disabled: false, required: preferredContact === 'Email', requiredText: 'Email required', ...contactConf[1] },
    { fieldName: 'residentPhone', controlType: 'localPhone', labelName: 'Phone', row: 1, colOffset: 0, hasEvent: false, disabled: false, required: preferredContact === 'Phone', requiredText: 'Phone required', ...contactConf[2] },

    { fieldName: 'registeringOnBehalf', controlType: 'checkbox', labelName: 'Registering on behalf of someone else', checkboxOnOwnLine: true, row: 1, colWidth: 24, colOffset: 0, hasEvent: true, disabled: false },
  ];

  const contactFields = [
    { fieldName: 'contactName', controlType: 'input', tabIndex: 10, labelName: 'Registered by Name', row: 2, required: true, requiredText: 'Name required', ...registeredByConf[0], colOffset: 0, hasEvent: false, disabled: false },
    { fieldName: 'contactPrefContactMethod', tooltip: 'Snow angel coordinators will use this only.', controlType: 'radio', options: options.preferredContactMethod, required: true, requiredText: 'Contact Method required', tabIndex: 11, labelName: 'Preferred Contact Method', row: 2, ...registeredByConf[1], colOffset: 0, hasEvent: true, disabled: false },
    { fieldName: 'contactEmail', controlType: 'input', labelName: 'Email', row: 2, colOffset: 0, ...registeredByConf[2], required: preferredContact2 === 'Email', requiredText: 'Email required', hasEvent: false, disabled: false },
    { fieldName: 'contactPhone', controlType: 'localPhone', labelName: 'Phone', row: 2, colOffset: 0, ...registeredByConf[3], required: preferredContact2 === 'Phone', requiredText: 'Phone required', hasEvent: false, disabled: false },
  ];

  const serviceAddressFields = [
    { fieldName: 'heading2', controlType: 'heading', labelName: 'Home Address', row: 3, colWidth: 24, colOffset: 0, hasEvent: false, disabled: false },

    { fieldName: 'unitNum', controlType: 'input', labelName: 'Unit Num', row: 3, ...colConf5[0], colOffset: 0, hasEvent: false, disabled: false },
    { fieldName: 'streetAddress', controlType: 'input', required: true, requiredText: 'Address required', labelName: 'Street Address', row: 3, ...colConf5[1], colOffset: 0, hasEvent: false, disabled: false },
  
    // { fieldName: 'addressLine2', controlType: 'input', labelName: 'Address Line 2', row: 3, ...colConf6[0], colOffset: 0, hasEvent: false, disabled: false },
    // { fieldName: 'buildingName', controlType: 'input', labelName: 'Building Name', row: 3, ...colConf6[1], colOffset: 0, hasEvent: false, disabled: false },

    { fieldName: 'city', controlType: 'select', options: options.city, tabIndex: 19, required: true, requiredText: 'City required', initialValue: 'Langley', labelName: 'City', row: 3, ...regionConf[0], colOffset: 0, hasEvent: false, disabled: false },
    { fieldName: 'region', controlType: 'select', options: options.provinces, initialValue: options.provinces.bc, allowClear: false, labelName: 'Province', row: 3, ...regionConf[1], ...regionConf[1], hasEvent: false, disabled: true },
    { fieldName: 'postalCode', controlType: 'input', labelName: 'Postal Code', required: true, requiredText: 'Prefix required', row: 3, ...regionConf[2],  hasEvent: true, disabled: false },
  ];

  const disclaimerFields = [
    { fieldName: 'disclaimer', controlType: 'card', labelName: 'Disclaimer', content: data.disclaimer, row: 4, colWidth: 24, colOffset: 0, hasEvent: false, disabled: false },
    { fieldName: 'acceptedDisclaimer', controlType: 'checkbox', largeLabel: true, labelName: 'Accept Disclaimer', checkboxOnOwnLine: true, required: true, requiredText: 'Accept Disclaimer required', row: 4, colWidth: 24, colOffset: 0, hasEvent: false, disabled: false },

  ];

  const allFields = [...registrantFields, ...(selfRegistered ? [] : contactFields),
  ...serviceAddressFields,
  ...disclaimerFields
  ];

  return (
    <Modal
      title='Request a Snow Angel'
      open={modalOpen}
      onClose={() => toggleOpen(false)}
      onCancel={() => toggleOpen(false)}
      width={windowWidth > 800 ? '768px' : windowWidth - 32}
      bodyStyle={{ padding: windowWidth < 768 ? '0px' : '16px', zIndex: 990 }}
      style={{ zIndex: 1000 }}
      footer={false}
      centered
      className='modal'
    >
      <FormBuilder
        formName='Sign up for a Snow Angel'
        onSubmit={handleSubmit}
        form={form}
        formFields={allFields}
        companyId={null}
        onChangeValue={handleFormValueChanged}
        showTitle={false}
        showButton={true}
        showDeleteButton={false}
        buttonAlignment='right'
        buttonCaption="Request a Snow Angel"
        columns={1}
      />
    </Modal>
  );
})