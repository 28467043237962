import React, { Component } from 'react';
import { DatePicker as AntDatePicker, Input } from 'antd';
import { getMoment, isValidDate } from '../../helpers/dateTime';


const dateFormat = 'DD-MMM-YYYY';

const getSelectedDate = ({ value, format = dateFormat }) => {
  try {
    return isValidDate(value) ? getMoment(value).format(format) : '';
  } catch (error) {
    return '';
  }
};

export default class DatePicker extends Component {
  render() {
    const {
      value,
      onChange = () => {},
      setTimeMode = 'start',
      readOnly,
      style,
      ...rest
    } = this.props;

    return readOnly ? (
      <Input
        value={getSelectedDate(this.props)}
        placeholder={rest.placeholder}
        disabled={rest.disabled}
        style={{ width: '100%', ...style }}
        readOnly
      />
    ) : (
      <AntDatePicker
        format={dateFormat}
        value={getMoment(value)}
        onChange={date => {
          return date
            ? onChange(
                new Date(
                  setTimeMode === 'start'
                    ? date.toDate().setHours(0, 0, 0, 0)
                    : setTimeMode === 'end'
                    ? date.toDate().setHours(23, 59, 59, 0)
                    : date.toDate()
                )
              )
            : onChange(null);
        }}
        style={{ width: '100%', ...style }}
        {...rest}
      />
    );
  }
}
