export const recipientType =
{
  homeowner: 'Homeowner',
  renter: 'Renter',
};

export const preferredContactMethod =
{
  email: 'Email',
  phone: 'Phone',
};
export const city = {
  aldergrove: 'Aldergrove',
  fortLangley: 'Fort Langley',
  langley: 'Langley',
};

export const provinces ={
  bc: 'BC',
  ab: 'AB',
  sk: 'SK',
  mb: 'MB',
  on: 'ON',
  qc: 'QC',
  nl: 'NL',
  pe: 'PE',
  ns: 'NS',
  nb: 'NB',
  yt: 'YT',
  nt: 'NT', 
  nu: 'NU'
  };