import './App.css';
import './components/FormBuilder/index.css';
import './Modals/index.css';
import AppLayout from './components/AppLayout';
import AppRouter from './components/AppRouter';
import { ProgramsContextProvider } from './contexts/ProgramsContext';
import { WindowWidthProvider } from './contexts/WindowWidthContext';

function App() {
  return (
    <div className="App">
      <WindowWidthProvider>
        <ProgramsContextProvider>
          <AppLayout>
            <AppRouter/>
          </AppLayout>
        </ProgramsContextProvider>
      </WindowWidthProvider>
    </div>
  );
}

export default App;
