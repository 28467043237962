import React, { useState, createContext, useContext } from 'react';
import axios from 'axios';
import { getConfig } from '../helpers/api';
import { baseUrl } from '../api';

const ProgramsContext = createContext({ programs: [] });

export const useProgramsContext = () => {
  const context = useContext(ProgramsContext);
  if (!context) {
    throw new Error("useProgramsContext must be used within a ProgramsContextProvider");
  }
  return context;
};

export const ProgramsContextProvider = ({ children }) => {
  const [data, setData] = useState({});

  const fetchPrograms = async () => {                              
    const response = await axios.get(`${baseUrl}/website/GetPublicPrograms`, getConfig());
    setData({programs: [...response.data]});
  };

  return (
    <ProgramsContext.Provider value={{ data, fetchPrograms}}>
      {children}
    </ProgramsContext.Provider>
  );
};
