export const getConfig = ({ responseType, acceptMimeType } = {}) => {
  const Accept = acceptMimeType
    ? {
        Accept: acceptMimeType,
      }
    : {};

  return {
    headers: {
      'Content-Type': 'application/json',
      ...Accept,
    },
    responseType,
  };
};



