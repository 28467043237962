import React from 'react';                
import MobileAppBar from './mobile';
import DesktopAppBar from './desktop';
import { useWindowWidth } from '../../contexts/WindowWidthContext';
import './index.css';

                                            
export default () => {      
  const windowWidth = useWindowWidth();

  return (                                
    <div>
      {windowWidth < 768 ? <MobileAppBar /> : <DesktopAppBar />}                 
    </div>                                
  );                                      
};