import React from 'react';
import { useLocation } from 'react-router-dom';
import FormBuilder from '../../components/FormBuilder';
import axios from 'axios';
import { baseUrl } from '../../api';
import { getConfig } from '../../helpers/api';
import { Form, notification } from 'antd';

function Feedback() {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // Get the values of the query parameters
  const companyId = queryParams.get('companyId');
  const eventId = queryParams.get('eventId');
  const snowfallId = queryParams.get('snowfallId');
  const memberId = queryParams.get('memberId');
  const recipientId = queryParams.get('recipientId');
  const [form] = Form.useForm();

  const validParams = (companyId !== null && eventId !== null && snowfallId !== null && memberId !== null && recipientId !== null)

  const feedbackFields = [
    { fieldName: 'feedbackVolunteer', controlType: 'note', rows: 4, labelName: 'Message for my Snow Angel', row: 1, colWidth: 24, maxLength: 300, largeLabel: true },
    { fieldName: 'feedbackProgram', controlType: 'note', rows: 6, labelName: 'Message for the Program Coordinator', row: 2, colWidth: 24, maxLength: 500, largeLabel: true },
  ];

  const handleSubmit = async formData => {
    try {
      notification?.destroy();

      if (formData.feedbackVolunteer === "" && formData.feedbackProgram === "") {
        notification.error({
          message: 'You must enter a message for either your snow angel or the program coordinator.', placement: window.innerWidth < 768 ? 'bottomLeft' : 'topRight', duration: null
        });
        return;
      }

      else {

        if (formData.feedbackVolunteer !== "") {
          const response = await axios.post(`${baseUrl}/CompanyProgram/PostProgramRecipientActivity`, {
            userId: '',
            companyId: companyId,
            eventId: eventId,
            snowfallId: snowfallId,
            memberId: memberId,
            recipientId: recipientId,
            activityType: 'Volunteer',
            emailBody: formData.feedbackVolunteer
          }, getConfig());
     
          if (response) {
            notification.success({ message: 'Successfully sent your message to your Snow Angel!', placement: window.innerWidth < 768 ? 'bottomLeft' : 'topRight', duration: null });
          }
          else {
            notification.error({
              message: 'Sorry something went wrong. Please try again later.', placement: window.innerWidth < 768 ? 'bottomLeft' : 'topRight', duration: null
            });
          }
        }

        if (formData.feedbackProgram !== "") {
          const responseProgram = await axios.post(`${baseUrl}/CompanyProgram/PostProgramRecipientActivity`, {
            userId: '',
            companyId: companyId,
            eventId: eventId,
            snowfallId: snowfallId,
            memberId: memberId,
            recipientId: recipientId,
            activityType: 'Program',
            emailBody: formData.feedbackProgram
          }, getConfig());

          console.log(responseProgram);


          if (responseProgram) {
            notification.success({ message: 'Successfully sent your feedback to the Snow Angel Coordinator', placement: window.innerWidth < 768 ? 'bottomLeft' : 'topRight', duration: null });
          }
          else {
            notification.error({
              message: 'Sorry something went wrong. Please try again later.', placement: window.innerWidth < 768 ? 'bottomLeft' : 'topRight', duration: null
            });
          }
        }
      }

    } catch (e) {
      notification.error({
        message: 'Sorry something went wrong. Please try again later.', placement: window.innerWidth < 768 ? 'bottomLeft' : 'topRight', duration: null
      });
    }
  }


  const componentStyleMobile = {
    margin: '16px 16px',
    textAlign: 'left',
    width: '100%'
  };

  const componentStyle = {
    position: 'absolute',
    left: '25%', // Position 25% from the left
    transform: 'translate(0%, 0%)', // Center the component
    textAlign: 'left',
  };



  return (
    <div>
      <h1>Snow Angel Program Feedback</h1>

      {validParams ?
        <div style={window.innerWidth < 768 ? componentStyleMobile : componentStyle}>
          <FormBuilder
            formName='Sendfeedback'
            onSubmit={handleSubmit}
            form={form}
            formFields={feedbackFields}
            companyId={null}
            showTitle={false}
            showButton={true}
            showDeleteButton={false}
            buttonAlignment='right'
            buttonCaption="Send Feedback"
            columns={1}
          />
        </div> : <h2 style={{ color: 'red' }}>Sorry this link is not valid.</h2>}
    </div>
  );

}

export default Feedback;  