import React, { useEffect, createRef } from 'react';  
import './index.css';
import { useWindowWidth } from '../../contexts/WindowWidthContext';
import { Button, Col,  Table } from 'antd';
import { useProgramsContext } from '../../contexts/ProgramsContext';
import Label from '../../elements/Label';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import RegisterModal from '../../Modals/Register';
      

export default function ProgramsList() {   
  const registerRef = createRef();

  const windowWidth = useWindowWidth();
  const {data, fetchPrograms} = useProgramsContext();

  useEffect(() => {
    fetchPrograms();
  }, []);

  const onOpenRegister = (data) => {
    registerRef?.current?.toggleOpen(true, data);
  }


  let columns = [];

  if (windowWidth < 768) {
    columns = [
      {
        title: 'Event',
        dataIndex: 'eventName',
        key: 'eventName',
        render: (text, record) => 
        <Col span={24}>
          <Label labelType="H6" caption={text} className='mobile-program-title'/>
          <br />
          <br />
          {record.imageURL !== '' && <img className='program-image' src={record.imageURL} alt={record.eventName} />}
          <br />
          {record.eventDescription}
          <br />
          <br/>
          {record.publicCanRegister ?
            <a href={record.eventURL} target="_blank" rel="noopener noreferrer"> <Button onClick={() => onOpenRegister(record)} type='primary' size='large' style={{ float: 'left' }} >I'd like a snow angel</Button></a>
            : <Label labelType={"Body1"} caption={'We are currently not accepting new registrations.'} />}
          <br />
          <br />
          {record.eventEmailAddress !== '' && <p><a href={"mailto:" + record.eventEmailAddress}>{record.eventEmailAddress}</a></p>}
          {record.eventWebAddress && <a href={record.eventWebAddress} target="_blank" rel="noopener noreferrer">  {record.eventWebAddress} </a>}
          {(record.eventFacebookAddress || record.eventInstagramAddress || record.eventTwitterAddress) && <div style={{ margin: '16px 0px 4px 0px' }}><Label labelType="Subtitle1" caption='Follow us' /></div>}
          {record.eventFacebookAddress && <a href={record.eventFacebookAddress} target="_blank" rel="noopener noreferrer"><FacebookIcon style={{ fontSize: '3.5em', marginRight: '8px' }} /></a>}
          {record.eventInstagramAddress && <a href={record.eventInstagramAddress} target="_blank" rel="noopener noreferrer"><InstagramIcon style={{ fontSize: '3.5em', marginRight: '8px' }} /></a>}
          {record.eventTwitterAddress && <a href={record.eventTwitterAddress} target="_blank" rel="noopener noreferrer"><TwitterIcon style={{ fontSize: '3.5em' }} /></a>}
        </Col>
    }];
  } else {
    columns = [
        {
            title: '',
            dataIndex: 'imageURL',
            key: 'imageURL',
            width: '332px',
            render: (text, record) =>
              <div className='column'>
                {record.imageURL !== '' ? 
                  <img className='program-image' src={record.imageURL} alt={record.eventName} /> :
                  <img className='program-image' src={record.imageURL} alt={record.eventName} />
                }
              </div>
        },
        {
            title: 'Event',
            dataIndex: 'eventName',
            key: 'eventName',
            // width: '70%',
            render: (text, record) => 
              <div className='column'>
                <Label labelType="H4" caption={text} />
                <br />
                <br />
                {record.eventDescription}
                <br />
                <br/>
                {record.publicCanRegister ?
                  <a href={record.eventURL} target="_blank" rel="noopener noreferrer"> <Button onClick={() => onOpenRegister(record)} type='primary' size='large' style={{ float: 'left' }} >I'd like a snow angel</Button></a>
                  : <Label labelType={"Body1"} caption={'We are currently not accepting new registrations.'} />}
                <br />
                <br />
                {record.eventEmailAddress !== '' && <p><a href={"mailto:" + record.eventEmailAddress}>{record.eventEmailAddress}</a></p>}
                {record.eventWebAddress && <a href={record.eventWebAddress} target="_blank" rel="noopener noreferrer">  {record.eventWebAddress} </a>}
                {(record.eventFacebookAddress || record.eventInstagramAddress || record.eventTwitterAddress) && <div style={{ margin: '16px 0px 4px 0px' }}><Label labelType="Subtitle1" caption='Follow us' /></div>}
                {record.eventFacebookAddress && <a href={record.eventFacebookAddress} target="_blank" rel="noopener noreferrer"><FacebookIcon style={{ fontSize: '4.5em', marginRight: '16px' }} /></a>}
                {record.eventInstagramAddress && <a href={record.eventInstagramAddress} target="_blank" rel="noopener noreferrer"><InstagramIcon style={{ fontSize: '4.5em', marginRight: '16px' }} /></a>}
                {record.eventTwitterAddress && <a href={record.eventTwitterAddress} target="_blank" rel="noopener noreferrer"><TwitterIcon style={{ fontSize: '4.5em' }} /></a>}
              </div>
        }
    ];
  } 

  return (                                
    <Col span={windowWidth >= 1600 ? 20 : 24} offset={windowWidth >= 1600 ? 2 : 0}>
        {data?.programs?.length > 0 ? (
          <Table
            rowKey="id"
            columns={columns}
            dataSource={data?.programs}
            showHeader={false}
            pagination={false}
            rowClassName={(record, index) => record.eventInterest !== 20 ? 'selected-row' : 'table-cell'}
            style={{ backgroundColor: 'white' }}
          />) : (
          <div style={{ backgroundColor: 'white', height: window.innerHeight - 260 }}>
            <Label labelType='Body2' caption='There are no programs available at this time' style={{ position: 'relative', top: '25%', width: '100%', textAlign: 'center' }} />
          </div>)
        }
        <RegisterModal ref={registerRef}/>
    </Col>
  );                                      
}