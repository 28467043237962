import React from "react";
import { HashRouter, Route, Routes, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import Feedback from "../pages/Feedback/";
const AppRouter = () => {
  return (
    <HashRouter>
      <Routes>
          <Route path="/" element={<Home/>} exact />
          <Route path="/feedback" element={<Feedback/>} exact />
          <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </HashRouter>
  );
};
export default AppRouter;
