import React, { Component } from 'react';
import { Form, Button, Input, Col, Row, Divider, Checkbox, AutoComplete, Select, InputNumber, Tooltip, Radio, Popconfirm, Alert, TimePicker, Card } from 'antd';
import Icon from '@ant-design/icons'
import { MaskedInput } from 'antd-mask-input';
import DatePicker from '../../elements/DatePicker'
import Label from '../../elements/Label';
import { isMobileOnly } from "react-device-detect";
import moment from 'moment';
import './index.css';

const Option = Select.Option;
const { TextArea } = Input;

export default class FormBuilder extends Component {

  state = {
    loading: false,
    fieldsLoaded: false,
    defaultsLoaded: false,
    disabled: false,
    selectAddOptions: [],
  };

  componentDidMount() {
    const { userId, companyId, data, getData, formFields } = this.props;

    if (getData) {
      if (companyId !== null)
        getData(userId, companyId);
      else
        getData(userId);
    };


    if (data != null && data.length > 0 && formFields.length > 0) {
      this.setFormFields();
    }

    if (data === null && formFields.length > 0) {
      this.setFormDefaults();
    }

  }

  componentDidUpdate(prevProps, prevState) {
    const { data, formFields } = this.props;
    const { fieldsLoaded, defaultsLoaded } = this.state;

    if (fieldsLoaded === true && data != null && data.length === 0 && formFields != null && formFields.length === 0) {
      this.setState({ fieldsLoaded: false, });
    }

    if (data != null && data.length > 0 && fieldsLoaded === false && formFields != null && formFields.length > 0) {
      this.setFormFields();
    }

    if (fieldsLoaded !== prevState.fieldsLoaded && fieldsLoaded === false && data.length > 0) {
      this.setFormFields();
    }

    if (data === null && formFields !== null && formFields.length > 0 && defaultsLoaded === false) {
      this.setFormDefaults();
    }

  }

  setSelectAddOptions = (options) => {
    this.setState({ selectAddOptions: options, });
  }

  setFormFields() {
    const { formFields, data, form } = this.props;
    const { fieldsLoaded } = this.state;

    let fieldName = "";

    if (fieldsLoaded !== true && formFields.length > 0) {
      if (data !== null) {
        form.setFieldsValue({ dirty: false });

        let dataRow = data[0];
        let formField = '';
        let controlType = '';
        let fieldValue = '';

        for (var i = 0; i < formFields.length; i++) {
          formField = formFields[i];
          fieldName = formField.fieldName;
          controlType = formField.controlType;

          if (controlType === 'selectMultiple' || controlType === 'checkbox.group') {
            if (formField.defaultValue === 'UseValueField') { fieldValue = [dataRow[fieldName]]; }
            else { fieldValue = formField.defaultValue; }
          }
          else { fieldValue = dataRow[fieldName]; }


          if (controlType === 'time') {
            fieldValue = moment(dataRow[fieldName], 'HH:mm:ss');
          }


          if (controlType === 'selectMultiple' || controlType === 'checkbox.group') {
            let selectValues = [];
            let currentValue = '';
            let selectRow = null;

            if (fieldValue !== undefined) {

              if (formField.defaultValue !== 'UseValueField') {
                for (var s = 0; s < fieldValue.length; s++) {
                  selectRow = fieldValue[s];
                  currentValue = selectRow[formField.fieldName];
                  selectValues.push(currentValue);
                }
                form.setFieldsValue({ [fieldName]: selectValues });
              }
              else {
                selectValues = fieldValue[0];
                form.setFieldsValue({ [fieldName]: selectValues });
              }

            };

          }
          else {
            if (fieldValue === undefined) {
              if (formField.controlType !== 'checkbox')
                form.setFieldsValue({ [fieldName]: formField.defaultValue });



            }
            else
              form.setFieldsValue({ [fieldName]: fieldValue });



          }



          this.setState({ fieldsLoaded: true, });
        }



      };



    };

  }

  setFormDefaults() {
    const { formFields, form } = this.props;
    const { defaultsLoaded } = this.state;

    let fieldName = "";
    let fieldValue = "";

    if (defaultsLoaded !== true && formFields.length > 0) {

      let formField = '';
      for (var i = 0; i < formFields.length; i++) {
        formField = formFields[i];
        fieldName = formField.fieldName;
        fieldValue = formField.defaultValue;

        if (formField.controlType === 'selectMultiple' || formField.controlType === 'checkbox.group') {
          let selectValues = [];
          let currentValue = '';
          let selectRow = null;

          if (fieldValue !== undefined) {

            if (formField.defaultValue !== 'UseValueField') {
              for (var s = 0; s < fieldValue.length; s++) {
                selectRow = fieldValue[s];
                currentValue = selectRow[formField.fieldName];
                selectValues.push(currentValue);
              }
              form.setFieldsValue({ [fieldName]: selectValues });
            }
            else {
              selectValues = fieldValue[0];
              form.setFieldsValue({ [fieldName]: selectValues });
            }


          }
          else {
            form.setFieldsValue({ [fieldName]: fieldValue });
          };
        }
        else {
          if (fieldValue !== undefined) {
            form.setFieldsValue({ [fieldName]: fieldValue });
          }

        };


      }

      this.setState({ defaultsLoaded: true, });
    };
  };

  changeFieldValue = (name, value, hasEvent) => {
    const { form, onChangeValue } = this.props;
    if (onChangeValue !== undefined && hasEvent === true && form)
      onChangeValue(name, value);
    form.setFieldsValue({ dirty: true });
  };

  changeTimeFieldValue = (name, time, timeString, hasEvent) => {
    const { form, onChangeValue } = this.props;
    if (onChangeValue !== undefined && hasEvent === true && form) {
      let newTime = moment(time).format('HH:mm:ss');
      onChangeValue(name, newTime);
    }
    form.setFieldsValue({ dirty: true });
  };

  changeSelectValue = (name, value, hasEvent) => {
    const { form, onChangeValue } = this.props;
    let fieldName = name;
    let newValue = '';
    if (value !== undefined) {
      newValue = value.value;
    }

    if (onChangeValue !== undefined && hasEvent === true && form) {
      onChangeValue(fieldName, newValue);
    };

    form.setFieldsValue({ dirty: true });
  };

  changeSelectMultipleValue = (name, value, hasEvent) => {
    const { form, onChangeValue } = this.props;
    let fieldName = name;
    let newValue = value;

    if (onChangeValue !== undefined && hasEvent === true && form) {
      onChangeValue(fieldName, newValue);
    };

    form.setFieldsValue({ dirty: true });
  };

  changeRadioValue = (name, value, hasEvent) => {
    const { form, onChangeValue } = this.props;
    let fieldName = name;
    let newValue = value;

    if (onChangeValue !== undefined && hasEvent === true && form) {
      onChangeValue(fieldName, newValue);
    };

    form.setFieldsValue({ dirty: true });
  };

  clickButton = (name) => {
    const { onButtonClick } = this.props;
    if (onButtonClick)
      onButtonClick(name);
  };

  clickIcon = (name) => {
    const { onIconClick } = this.props;
    if (onIconClick)
      onIconClick(name);
  };

  onSearch = (value) => {
  }

  render() {

    const {
      formName,
      formFields,
      getData,
      onSubmit,
      onButtonClick,
      onIconClick,
      onChangeValue,
      onDelete,
      onPrint,
      data,
      userId,
      companyId,
      showTitle,
      showModal,
      showButton,
      showDeleteButton,
      buttonCaption,
      promptButton,
      promptText,
      showPrintButton,
      printButtonCaption,
      deleteButtonCaption,
      deleteConfirmation,
      showPrimaryButton,
      primaryButtonCaption,
      primaryButtonPrompt,
      onPrimaryButtonClick,
      buttonAlignment,
      customControl1,
      customControl2,
      customControl3,
      customControl4,
      customControl5,
      modal,
      columns,
      disabledFields,
      form,
      initialValues,
      ...rest
    } = this.props;

    const { selectAddOptions } = this.state;

    let lastRow = 1;
    let firstRow = true;
    let formTitle = null;
    let saveButton = null;
    let deleteButton = null;
    let printButton = null;
    let primaryButton = null;
    let autoFocus = true;

    if (showTitle)
      formTitle = <Form.Item  >
        <label labelType="H5" caption={formName} />
      </Form.Item>;

    if (showButton === true)
      saveButton = <Form.Item style={{ float: buttonAlignment, marginBottom: '0px', marginRight: '32px' }}  >
        {promptButton ?
          <Popconfirm
            title={promptText}
            onConfirm={onSubmit}
            okText="Yes"
            cancelText="No">

            <Button style={{ minWidth: '88px', margin: '16px' }} type="primary" block>
              {buttonCaption ? buttonCaption : 'Save'}
            </Button>
          </Popconfirm> :
          <Button style={{ minWidth: '88px', margin: '16px' }} type="primary" htmlType="submit" block>
            {buttonCaption ? buttonCaption : 'Save'}
          </Button>
        }
      </Form.Item>;

    if (showDeleteButton === true)
      deleteButton = <Form.Item style={{ float: buttonAlignment, marginBottom: '0px', marginRight: '16px' }}  >
        <Popconfirm
          title={deleteConfirmation ? deleteConfirmation : 'Are you sure you want to delete?'}
          onConfirm={onDelete}
          okText="Yes"
          cancelText="No">
          <Button style={{ minWidth: '88px', margin: '16px' }} type="default" block>
            {deleteButtonCaption ? deleteButtonCaption : 'Delete'}
          </Button>
        </Popconfirm>
      </Form.Item>;

    if (showPrintButton === true)
      printButton = <Form.Item style={{ float: buttonAlignment, marginBottom: '0px', marginRight: '16px' }}  >

        <Button style={{ minWidth: '88px', margin: '16px' }} type="default" block onClick={onPrint}>
          {printButtonCaption ? printButtonCaption : 'Print'}
        </Button>

      </Form.Item>;

    if (showPrimaryButton === true)
      primaryButton = <Form.Item style={{ float: buttonAlignment, marginBottom: '0px', marginRight: '16px' }}  >

        <Button style={{ minWidth: '88px', margin: '16px' }} type="primary" block onClick={onPrimaryButtonClick}>
          {primaryButtonCaption}
        </Button>

      </Form.Item>;

    let Modal = null;
    if (showModal === true)
      Modal = modal;

    let FormFields = null;
    let currentTabIndex = 1;
    let hasRequiredFields = false;
    let currentLabelName = '';
    let disabled = false;
    let newTabIndex = 0;
    let tooltip = '';

    if (formFields.length > 0) {

      let control = null;
      let newRow = null;

      FormFields = formFields.map(formField => {

        newRow = null;
        if (formField.hasEvent === undefined)
          formField.hasEvent = true;

        if (formField.tooltip !== undefined)
          tooltip = formField.tooltip;
        else
          tooltip = '';

        if (formField.column === 1 || columns === 1) {

          if (formField.disabled !== undefined) {
            disabled = formField.disabled;
          }
          else { disabled = false; }


          if (disabledFields !== undefined && disabledFields !== null && Array.isArray(disabledFields)) {
            disabled = disabledFields.includes(formField.fieldName);
          };

          if (formField.row > lastRow && !firstRow) {
            newRow = <Divider style={{ display: 'block', height: '0px', margin: '16px 0 8px 0', padding: '0px' }} />;
            lastRow = formField.row;

          };

          if (formField.tabIndex > 0 || formField.tabIndex === undefined) {
            if (firstRow === false)
              newTabIndex = currentTabIndex + 1;
            else
              newTabIndex = 1;
          }

          if (formField.required === true && hasRequiredFields === false) {
            hasRequiredFields = true;
          }

          firstRow = false;
          control = null;
          if (formField.required === true && formField.labelName !== '') { currentLabelName = formField.labelName + ' *'; }
          else {
            currentLabelName = formField.labelName;
          }

          let selectOptions = null;
          let hasLargeLabel = false;
          if (formField.largeLabel === true)
            hasLargeLabel = true;



          if (newTabIndex > 0)
            currentTabIndex = newTabIndex;

          if (newTabIndex !== 1)
            autoFocus = false;
          else
            autoFocus = true;

          switch (formField.controlType) {
            case "input":
              control = <>
                <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }}   >{currentLabelName}</label></Tooltip>
                <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} colon={false} key={formField.fieldName}
                  className='form-item' initialValue={formField.initialValue} >
                  <Input
                    tabIndex={newTabIndex}
                    name={formField.fieldName}
                    size="large"
                    autoFocus={autoFocus}
                    enabled={formField.enabled ? true : formField.enabled}
                    autoComplete={'off'}
                    allowClear={formField.allowClear}
                    maxLength={formField.valueLength}
                    placeholder={formField.placeholder}
                    disabled={disabled}
                    onChange={e => this.changeFieldValue(formField.fieldName, e.target.value, formField.hasEvent)}
                  />
                </Form.Item>
              </>;
              break;

            case "integer":
              control =
                <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} colon={false} key={formField.fieldName} className='form-item'  >
                  <Tooltip title={tooltip}>   <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>
                  <InputNumber
                    tabIndex={newTabIndex}
                    name={formField.fieldName}
                    autoFocus={autoFocus}
                    size="large"
                    precision={0}
                    min={0}
                    style={{ width: '100%' }}
                    placeholder={formField.placeholder}
                    disabled={disabled}
                    onChange={e => this.changeFieldValue(formField.fieldName, e, formField.hasEvent)}

                  />
                </Form.Item>;
              break;

            case "phone":
              control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} colon={false} key={formField.fieldName} className='form-item' >
                <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>

                <MaskedInput
                  tabIndex={newTabIndex}
                  name={formField.fieldName}
                  autoComplete={'off'}
                  autoFocus={autoFocus}
                  size="large"
                  mask={"1(111) 111-1111"}
                  allowClear={formField.allowClear}
                  maxLength={formField.valueLength}
                  placeholder={formField.placeholder}
                  disabled={disabled}
                  onChange={e => this.changeFieldValue(formField.fieldName, e.target.value, formField.hasEvent)}
                />
              </Form.Item>;
              break;

            case "extphone":
              control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} colon={false} key={formField.fieldName} className='form-item' >
                <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>
                <MaskedInput
                  tabIndex={newTabIndex}
                  name={formField.fieldName}
                  autoComplete={'off'}
                  autoFocus={autoFocus}
                  size="large"
                  mask={"1(111) 111-1111 x111111"}
                  allowClear={formField.allowClear}
                  maxLength={formField.valueLength}
                  placeholder={formField.placeholder}
                  disabled={disabled}
                  onChange={e => this.changeFieldValue(formField.fieldName, e.target.value, formField.hasEvent)}
                />
              </Form.Item>;
              break;

            case "cell":
              control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} colon={false} key={formField.fieldName} className='form-item' >
                <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>

                <MaskedInput
                  tabIndex={newTabIndex}
                  name={formField.fieldName}
                  autoComplete={'off'}
                  autoFocus={autoFocus}
                  size="large"
                  mask={"1(111) 111-1111"}
                  allowClear={formField.allowClear}
                  maxLength={formField.valueLength}
                  placeholder={formField.placeholder}
                  disabled={disabled}
                  onChange={e => this.changeFieldValue(formField.fieldName, e.target.value, formField.hasEvent)}
                />
              </Form.Item>;
              break;

            case "localPhone":
              control = <>
                <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>
                <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} colon={false} key={formField.fieldName} className='form-item' >
                  <MaskedInput
                    tabIndex={newTabIndex}
                    name={formField.fieldName}
                    autoComplete={'off'}
                    autoFocus={autoFocus}
                    size="large"
                    mask={'(000) 000-0000'}
                    required={formField.required}
                    allowClear={formField.allowClear}
                    maxLength={formField.valueLength}
                    placeholder={formField.placeholder}
                    disabled={disabled}
                    onChange={e => this.changeFieldValue(formField.fieldName, e.target.value, formField.hasEvent)}
                  />
                </Form.Item>
              </>;
              break;

            case "localCell":
              control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} colon={false} key={formField.fieldName} className='form-item' >
                <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>
                <MaskedInput
                  tabIndex={newTabIndex}
                  name={formField.fieldName}
                  autoComplete={'off'}
                  autoFocus={autoFocus}
                  size="large"
                  mask={'(000) 000-0000'}
                  allowClear={formField.allowClear}
                  maxLength={formField.valueLength}
                  placeholder={formField.placeholder}
                  disabled={disabled}
                  onChange={e => this.changeFieldValue(formField.fieldName, e.target.value, formField.hasEvent)}
                />
              </Form.Item>;
              break;
           
            case "password":
              control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} colon={false} key={formField.fieldName} className='form-item'>
                <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>
                <Input.Password
                  tabIndex={newTabIndex}
                  name={formField.fieldName}
                  autoFocus={autoFocus}
                  size="large"
                  autoComplete={'off'}
                  allowClear={formField.allowClear}
                  placeholder={formField.placeholder}
                  disabled={disabled}
                  onChange={e => this.changeFieldValue(formField.fieldName, e.target.value, formField.hasEvent)}
                />
              </Form.Item>;
              break;

            case "date":
              control = <>
                <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>
                <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} colon={false} key={formField.fieldName} className='form-item' >
                  <DatePicker
                    tabIndex={newTabIndex}
                    name={formField.fieldName}
                    autoFocus={autoFocus}
                    showToday
                    disabledDate={formField.disabledDate}
                    placeholder={formField.placeholder !== undefined && formField.placeholder !== "" ? formField.placeholder : 'dd-mmm-yyyy'}
                    onChange={e => this.changeFieldValue(formField.fieldName, e, formField.hasEvent)}
                    allowClear={formField.required === true ? false : true}
                    disabled={disabled}
                    size="large" />
                </Form.Item>
              </>;
              break;

            case "time":
              control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} colon={false} key={formField.fieldName} className='form-item' >
                <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>
                <TimePicker
                  use12Hours
                  tabIndex={newTabIndex}
                  name={formField.fieldName}
                  autoFocus={autoFocus}
                  defaultOpenValue={moment('10:00:00', 'h:mm A')}
                  format="h:mm A"
                  minuteStep={15}
                  placeholder={formField.placeholder !== undefined && formField.placeholder !== "" ? formField.placeholder : 'time'}
                  onChange={(time, timeString) => this.changeTimeFieldValue(formField.fieldName, time, timeString, formField.hasEvent)}
                  allowClear={formField.required === true ? false : true}
                  disabled={disabled}
                  size="large" />
              </Form.Item>;
              break;

            case "note":
              let noteMargin = '4px 0px';
              control = <>
                <Tooltip title={tooltip}>
                  <label htmlFor={formField.fieldName} style={{ fontSize: '20px', textAlign: 'left' }}  >{currentLabelName} </label></Tooltip>
                <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} colon={false} key={formField.fieldName} style={{ margin: noteMargin }} >
                  <TextArea
                    tabIndex={newTabIndex}
                    name={formField.fieldName}
                    autoFocus={autoFocus}
                    size="large"
                    rows={formField.rows ? formField.rows : 3}
                    maxLength={formField.valueLength}
                    placeholder={formField.placeholder}
                    disabled={disabled}
                    onChange={e => this.changeFieldValue(formField.fieldName, e.target.value, formField.hasEvent)}
                  />
                </Form.Item>
              </>;
              break;

            case "label":
              control = <>
                <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{formField.labelName} </label></Tooltip>
                <Form.Item name={formField.fieldName} colon={false} key={formField.fieldName} className='form-item' >
                  <Input
                    name={formField.fieldName}
                    size="large"
                    disabled={true}
                    style={{ color: 'black', fontWeight: '300' }}
                  />
                </Form.Item>
              </>;
              break;

            case "card":
              control =
                <Form.Item name={formField.fieldName} colon={false} key={formField.fieldName} className='form-item' >
                  <Card
                    name={formField.fieldName} style={{ padding: '0px', margin: '0px', fontWeight: '500', fontSize: '16px', backgroundColor: '#ffbf47' }}>
                    {formField.content}
                  </Card>
                </Form.Item>;

              break;

            case "heading":
              control = <Form.Item className='heading' colon={false} key={formField.fieldName} style={{ margin: '0px 0 8px 0' }} >
                <Label labelType="Subtitle1" className="label" style={{ fontWeight: '900' }} caption={formField.labelName} />
              </Form.Item>;
              break;

            case "headingLarge":
              control = <Form.Item colon={false} key={formField.fieldName} style={lastRow === 1 ? { margin: '4px 0px' } : { margin: '16px 0px 0px 0px' }} >
                <Label
                  labelType="H6" caption={formField.labelName} style={{ padding: '0px 0px', width: '100%' }} />
              </Form.Item>;
              break;

            case "alert":
              control = <Form.Item colon={false} key={formField.fieldName} style={lastRow === 1 ? { margin: '4px 0px' } : { margin: '44px 0px 0px 0px' }} >
                <Alert message={formField.labelName} type={formField.options} />
              </Form.Item>;
              break;

            case "money":
              control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} colon={false} key={formField.fieldName} style={{ margin: '4px 0px', width: '100%' }} >
                <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{formField.labelName} {formField.required === true ? '*' : ''}</label></Tooltip>
                <InputNumber
                  tabIndex={newTabIndex}
                  name={formField.fieldName}
                  autoFocus={autoFocus}
                  size="large"
                  precision={2}
                  min={0.00}
                  formatter={value => `${value}`}
                  style={{ width: '100%' }}
                  placeholder={formField.placeholder}
                  disabled={disabled}
                  onChange={e => this.changeFieldValue(formField.fieldName, e, formField.hasEvent)}
                />
              </Form.Item>;
              break;

            case "startingBalance":
              control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} colon={false} key={formField.fieldName} style={{ margin: '4px 0px', width: '100%' }} >
                <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{formField.labelName} {formField.required === true ? '*' : ''}</label></Tooltip>

                <InputNumber
                  tabIndex={newTabIndex}
                  name={formField.fieldName}
                  autoFocus={autoFocus}
                  size="large"
                  precision={2}
                  min={-1000}
                  formatter={value => `${value}`}
                  style={{ width: '100%' }}
                  placeholder={formField.placeholder}
                  disabled={disabled}
                  onChange={e => this.changeFieldValue(formField.fieldName, e, formField.hasEvent)}
                />
              </Form.Item>;
              break;

            case "checkbox":
              let checkboxMargin = '40px 0px 0px 0px';
              if (formField.checkboxOnOwnLine === true)
                checkboxMargin = "4px 0px 4px 0px";

              control = <Form.Item name={formField.fieldName} valuePropName='checked' colon={false} key={formField.fieldName}
                style={formField.onHeadingBackground ? { backgroundColor: '#B2D7ED', margin: checkboxMargin, marginLeft: '-16px', outline: 'none', padding: '0px', border: 'none', shadow: 'none' } : { margin: checkboxMargin }}>
                {/* {formField.placeholder ? <React.Fragment><Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{formField.placeholder}</label></Tooltip><br /></React.Fragment> : null} */}
                <Checkbox
                  tabIndex={newTabIndex}
                  style={formField.onHeadingBackground ? { padding: '0px', width: '100%', fontWeight: 800, fontSize: '14px', margin: '0px' } : { fontWeight: 400, fontSize: hasLargeLabel ? '20px' : '16px' }}
                  onChange={e => this.changeFieldValue(formField.fieldName, e.target.checked, formField.hasEvent)}
                  autoFocus={autoFocus}
                  disabled={disabled}
                >
                  <Tooltip title={tooltip}> {formField.labelName}</Tooltip>
                </Checkbox>
              </Form.Item>;
              break;

            case "select":

              if (formField.options !== undefined && formField.options !== '') {
                selectOptions = formField.options;
                control = <>
                  <Tooltip title={tooltip}>
                    <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }}>{currentLabelName}</label>
                  </Tooltip>
                  <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} colon={false} key={formField.fieldName}
                    className='form-item' style={{ fontWeight: 500 }} initialValue={formField.initialValue}>
                    <Select size="large"
                      tabIndex={newTabIndex}
                      placeholder={formField.placeholder}
                      allowClear={true}
                      // autoComplete={'off'}
                      autoFocus={autoFocus}
                      showSearch
                      defaultValue={formField.default}
                      optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onSearch={this.onSearch}
                      disabled={disabled}
                      onChange={(key, value) => this.changeSelectValue(formField.fieldName, value, formField.hasEvent)}>
                      {Object.entries(selectOptions).map(([key, value]) =>
                      (
                        <Option key={key} value={value} >
                          {value}
                        </Option>
                      ))}
                    </Select >
                  </Form.Item>
                </>
                  ;
              }
              else {
                selectOptions = formField.dataList;


                control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} valuePropName='value' colon={false} key={formField.fieldName} className='form-item'>
                  <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>

                  <Select size="large"
                    tabIndex={newTabIndex}
                    placeholder={formField.placeholder}
                    allowClear={true}
                    showSearch
                    autoComplete={'off'}
                    autoFocus={autoFocus}
                    optionFilterProp="children"
                    disabled={disabled}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onSearch={this.onSearch}
                    onChange={(key, value) => this.changeSelectValue(formField.fieldName, value, formField.hasEvent)}
                  >
                    {selectOptions !== undefined ?
                      selectOptions.map(row =>
                      (
                        <Option key={row[formField.dataKey]} value={formField.dataValueNotKey === true ? row[formField.dataValue] : row[formField.dataKey]} >
                          {row[formField.dataValue]}
                        </Option>
                      )) : null
                    }
                  </Select >
                </Form.Item>;

              };

              break;

            case "selectAdd":
              if (formField.options !== undefined && formField.options !== '') {
                selectOptions = formField.options;

                control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} valuePropName='value' colon={false} key={formField.fieldName} className='form-item' style={{ fontWeight: 500 }}>
                  <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }}>{currentLabelName}</label></Tooltip>

                  <AutoComplete size="large"
                    tabIndex={newTabIndex}
                    autoFocus={autoFocus}
                    autoComplete="off"
                    allowClear={true}
                    placeholder={formField.placeholder}
                    disabled={disabled}
                    options={selectAddOptions}
                    onChange={(value) => this.changeFieldValue(formField.fieldName, value, formField.hasEvent)}
                    onSearch={(searchText) => {

                      if (selectOptions)


                        this.setSelectAddOptions(
                          !searchText ? [] : [selectOptions].includes(searchText)
                        );

                    }}
                  >
                  </AutoComplete >
                </Form.Item>;
              }
              else {
                selectOptions = formField.dataList;


                control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} valuePropName='value' colon={false} key={formField.fieldName} className='form-item'>
                  <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>
                  <AutoComplete size="large"
                    tabIndex={newTabIndex}
                    autoFocus={autoFocus}
                    autoComplete="off"
                    allowClear={true}
                    placeholder={formField.placeholder}
                    disabled={disabled}
                    options={selectAddOptions}
                    onChange={(value) => this.changeFieldValue(formField.fieldName, value, formField.hasEvent)}
                    onSearch={(searchText) => {
                      if (selectOptions)
                        this.setSelectAddOptions(
                          !searchText ? [] : selectOptions.filter(o => o[formField.dataValue].includes(searchText))
                        );

                    }}
                  >
                  </AutoComplete >
                </Form.Item>;
              };

              break;

            case "selectMultiple":
              let selectMultipleOptions = null;
              selectMultipleOptions = formField.dataList;

              control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} valuePropName='value' colon={false} key={formField.fieldName} className='form-item'>
                <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>

                <Select
                  tabIndex={newTabIndex}
                  autoFocus={autoFocus}
                  // showAction={["focus"]}
                  size="large"
                  mode="multiple"
                  placeholder={formField.placeholder}
                  allowClear={true}
                  disabled={disabled}
                  onChange={(key, value) => this.changeSelectMultipleValue(formField.fieldName, value, formField.hasEvent)}
                >
                  {
                    selectMultipleOptions !== undefined ?
                      selectMultipleOptions.map(row =>
                      (
                        <Option key={formField.dataValueNotKey === true ? row[formField.dataValue] : row[formField.dataKey]} value={formField.dataValueNotKey === true ? row[formField.dataValue] : row[formField.dataKey]} >
                          {row[formField.dataValue]}
                        </Option>
                      )) : null
                  }
                </Select >
              </Form.Item>;


              break;

            case "radio":
              let radioOptions = null;
              if (formField.options !== undefined && formField.options !== '') {
                radioOptions = formField.options;
                control = <React.Fragment>
                  <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }}>{currentLabelName}</label></Tooltip>
                  <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} valuePropName='value' colon={false} key={formField.fieldName} className='form-item' style={{ fontWeight: 500 }}>
                  <Radio.Group
                     autoFocus={autoFocus}
                    disabled={disabled}
                    onChange={(e) => this.changeRadioValue(formField.fieldName, e.target.value, formField.hasEvent)}
                  >
                    {Object.entries(radioOptions).map(([key, value]) =>
                    (
                      <Radio value={value} key={key} style={{fontSize:'16px', fontWeight:500,marginTop:'4px'}} tabIndex={newTabIndex} >
                        {value}
                      </Radio >
                    ))
                    }
                  </Radio.Group >
                </Form.Item>
                </React.Fragment>;
              }
              else {
                radioOptions = formField.dataList;

                control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} valuePropName='value' colon={false} key={formField.fieldName} className='form-item'>
                  <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>

                  <Radio.Group
                    tabIndex={newTabIndex}
                    autoFocus={autoFocus}
                    disabled={disabled}
                    onChange={(e) => this.changeRadioValue(formField.fieldName, e.target.value, formField.hasEvent)}
                  >
                    {radioOptions ?

                      radioOptions.map(row =>
                      (
                        <Radio key={row[formField.dataKey]} style={formField.radioStyle} value={formField.dataValueNotKey === true ? row[formField.dataValue] : row[formField.dataKey]} >
                          {row[formField.dataValue]}
                        </Radio>
                      ))
                      : null}
                  </Radio.Group >
                </Form.Item>;

              }
              break;


            case "radio.button":
              let radioButtonOptions = null;

              if (formField.options !== undefined && formField.options !== '') {
                radioButtonOptions = formField.options;
                control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} valuePropName='value' colon={false} key={formField.fieldName} className='form-item' style={{ fontWeight: 500, display: 'block' }}>
                  <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} >{currentLabelName}</label></Tooltip> <br></br>

                  <Radio.Group
                    buttonStyle={formField.radioStyle}
                    tabIndex={newTabIndex}
                    autoFocus={autoFocus}
                    disabled={disabled}
                    onChange={(e) => this.changeRadioValue(formField.fieldName, e.target.value, formField.hasEvent)}
                  >
                    {Object.entries(radioButtonOptions).map(([key, value]) =>
                    (
                      <Radio.Button value={value} key={key}  >
                        {value}
                      </Radio.Button >
                    ))
                    }
                  </Radio.Group >
                </Form.Item>;
              }
              else {
                radioButtonOptions = formField.dataList;

                control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} valuePropName='value' colon={false} key={formField.fieldName} className='form-item'>
                  <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} style={{ fontWeight: 500, fontSize: '14px', display: 'block' }} >{currentLabelName}</label></Tooltip>


                  <Radio.Group
                    buttonStyle={formField.radioStyle}
                    tabIndex={newTabIndex}
                    autoFocus={autoFocus}
                    disabled={disabled}
                    onChange={(e) => this.changeRadioValue(formField.fieldName, e.target.value, formField.hasEvent)}
                  >
                    {radioButtonOptions ? radioButtonOptions.map(row =>
                    (
                      <Radio.Button key={row[formField.dataKey]} value={formField.dataValueNotKey === true ? row[formField.dataValue] : row[formField.dataKey]} >
                        {row[formField.dataValue]}
                      </Radio.Button>
                    )) : null
                    }
                  </Radio.Group >
                </Form.Item>;

              }
              break;

            case "checkbox.group":

              let colWidth = 24;
              if (formField.valueLength === 2)
                colWidth = 12;
              if (formField.valueLength === 3)
                colWidth = 8;
              if (formField.valueLength === 4)
                colWidth = 6;
              if (isMobileOnly)
                colWidth = 24;


              if (formField.options !== undefined && formField.options !== '') {
                let checkBoxList = formField.options;
                control = <Form.Item colon={false} key={formField.fieldName} className='form-item' style={{ fontWeight: 500, display: 'block' }}>
                  <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip><br />
                  <Tooltip title={tooltip}>
                    <Checkbox.Group name={formField.fieldName}
                      tabIndex={newTabIndex}
                      autoFocus={autoFocus}
                      onChange={(value) => this.changeSelectMultipleValue(formField.fieldName, value, formField.hasEvent)}>
                      {checkBoxList.map(row =>
                      (
                        <Col span={colWidth}>
                          <Tooltip title={row['value']} >
                            <Checkbox value={row['value']}> {row['id']}   </Checkbox>
                          </Tooltip>
                        </Col>
                      ))
                      }
                    </Checkbox.Group>
                  </Tooltip>
                </Form.Item>;
              }
              else {

                let checkBoxData = formField.dataList;

                control = <Form.Item name={formField.fieldName} valuePropName='value' colon={false} key={formField.fieldName} className='form-item' style={{ fontWeight: 500, display: 'block' }}>
                  <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip><br />


                  <Checkbox.Group name={formField.fieldName}
                    tabIndex={newTabIndex}
                    autoFocus={autoFocus}
                    onChange={(value) => this.changeSelectMultipleValue(formField.fieldName, value, formField.hasEvent)}>
                    {checkBoxData ? checkBoxData.map(row =>
                    (
                      <Col span={colWidth} key={row['listKey']}>
                        <Tooltip title={row['listValue']} >
                          <Checkbox style={{ fontSize: '14px' }} value={row['listKey']}> {row['listKey']}   </Checkbox>
                        </Tooltip>

                      </Col>
                    ))
                      : null
                    }
                  </Checkbox.Group>



                </Form.Item>;

              }


              break;

            case "button":
              control =
                <Tooltip title={tooltip}> <Button
                  name={formField.fieldName}
                  block
                  style={{ marginTop: '24px', width: '100%' }}
                  tabIndex={newTabIndex}
                  type={formField.buttonStyle}
                  disabled={disabled}
                  onClick={e => this.clickButton(formField.fieldName)}
                >
                  {formField.labelName}
                </Button></Tooltip>

              break;

            case "icon":
              if (formField.defaultValue === true)
                control =
                  <Tooltip title={formField.labelName} >
                    <Icon
                      name={formField.fieldName}
                      type={formField.valueType}
                      theme="filled"
                      disabled={disabled}
                      style={{ marginTop: '42px', marginLeft: '8px', width: '100%', fontSize: formField.valueLength }}
                      onClick={e => this.clickIcon(formField.fieldName)}
                      aria-label={formField.labelName}
                    />
                  </Tooltip>;

              break;


            case "customControl1":
              control = customControl1;
              break;
            case "customControl2":
              control = customControl2;
              break;
            case "customControl3":
              control = customControl3;
              break;
            case "customControl4":
              control = customControl4;
              break;
            case "customControl5":
              control = customControl5;
              break;

            default:
          };

          return (
            <React.Fragment key={"item" + formField.fieldName} >
              {newRow}
              {formField.newLine && <Col span={24} />}
              <Col span={formField.colWidth} offset={formField.colOffset} >
                {control}
              </Col>
            </React.Fragment>)

        }
        else {
          return null;
        }

      }



      )
    };


    // let FormFields2 = null;
    lastRow = 1;
    firstRow = true;

    // if (columns === 2) {

    //   if (formFields.length > 0) {

    //     let control = null;
    //     let newRow = null;
    //     disabled = false;

    //     FormFields2 = formFields.map(formField => {
    //       newRow = null;

    //       if (formField.tooltip !== undefined)
    //         tooltip = formField.tooltip;
    //       else
    //         tooltip = '';


    //       let hasLargeLabel = false;
    //       if (formField.largeLabel === true)
    //         hasLargeLabel = true;

    //       if (formField.column === 2 && columns === 2) {

    //         if (formField.disabled !== undefined) {
    //           disabled = formField.disabled;
    //         }
    //         else { disabled = false; }

    //         if (disabledFields !== undefined && disabledFields !== null && Array.isArray(disabledFields)) {
    //           disabled = disabledFields.includes(formField.fieldName);
    //         };

    //         if (formField.row > lastRow && !firstRow) {
    //           newRow = <Divider style={{ display: 'block', height: '0px', margin: '0px', padding: '0px' }} />;
    //           lastRow = formField.row;
    //         };

    //         let newTabIndex = -1;
    //         if (formField.tabIndex !== -1) {
    //           newTabIndex = currentTabIndex + 1;
    //         }

    //         if (newTabIndex > 0)
    //           currentTabIndex = newTabIndex;

    //         if (formField.required === true && hasRequiredFields === false) {
    //           hasRequiredFields = true
    //         }

    //         if (formField.required === true && formField.labelName !== '') { currentLabelName = formField.labelName + ' *'; }
    //         else {
    //           currentLabelName = formField.labelName;
    //         }




    //         firstRow = false;
    //         control = null;
    //         switch (formField.controlType) {
    //           case "input":
    //             control =

    //               <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} colon={false} key={formField.fieldName} className='form-item'  >
    //                 <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>

    //                   <Input
    //                     tabIndex={newTabIndex}
    //                     name={formField.fieldName}
    //                     size="large"
    //                     enabled={formField.enabled ? true : formField.enabled}
    //                     autoComplete={'off'}
    //                     allowClear={formField.allowClear}
    //                     maxLength={formField.valueLength}
    //                     placeholder={formField.placeholder}
    //                     disabled={disabled}
    //                     onChange={e => this.changeFieldValue(formField.fieldName, e.target.value, formField.hasEvent)}
    //                   />

    //               </Form.Item>;

    //             break;
    //           case "integer":
    //             control =
    //               <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} colon={false} key={formField.fieldName} className='form-item'  >
    //                 <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>

    //                   <InputNumber
    //                     tabIndex={newTabIndex}
    //                     name={formField.fieldName}
    //                     size="large"
    //                     precision={0}
    //                     min={0}
    //                     style={{ width: '100%' }}
    //                     placeholder={formField.placeholder}
    //                     disabled={disabled}
    //                     onChange={e => this.changeFieldValue(formField.fieldName, e, formField.hasEvent)}

    //                   />

    //               </Form.Item>;
    //             break;
    //           case "phone":
    //             control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} colon={false} key={formField.fieldName} className='form-item' >
    //               <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>

    //                 <MaskedInput
    //                   tabIndex={newTabIndex}
    //                   name={formField.fieldName}
    //                   autoComplete={'off'}
    //                   size="large"
    //                   mask={"1(111) 111-1111"}
    //                   allowClear={formField.allowClear}
    //                   maxLength={formField.valueLength}
    //                   placeholder={formField.placeholder}
    //                   disabled={disabled}
    //                   onChange={e => this.changeFieldValue(formField.fieldName, e.target.value, formField.hasEvent)}
    //                 />

    //             </Form.Item>;
    //             break;

    //           case "extphone":
    //             control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} colon={false} key={formField.fieldName} className='form-item' >
    //               <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>

    //                 <MaskedInput
    //                   tabIndex={newTabIndex}
    //                   name={formField.fieldName}
    //                   autoComplete={'off'}
    //                   autoFocus={autoFocus}
    //                   size="large"
    //                   mask={"1(111) 111-1111 x111111"}
    //                   allowClear={formField.allowClear}
    //                   maxLength={formField.valueLength}
    //                   placeholder={formField.placeholder}
    //                   disabled={disabled}
    //                   onChange={e => this.changeFieldValue(formField.fieldName, e.target.value, formField.hasEvent)}
    //                 />

    //             </Form.Item>;
    //             break;

    //           case "cell":
    //             control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} colon={false} key={formField.fieldName} className='form-item' >
    //               <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>

    //                 <MaskedInput
    //                   tabIndex={newTabIndex}
    //                   name={formField.fieldName}
    //                   autoComplete={'off'}
    //                   size="large"
    //                   mask={"1(111) 111-1111"}
    //                   allowClear={formField.allowClear}
    //                   maxLength={formField.valueLength}
    //                   placeholder={formField.placeholder}
    //                   disabled={disabled}
    //                   onChange={e => this.changeFieldValue(formField.fieldName, e.target.value, formField.hasEvent)}
    //                 />

    //             </Form.Item>;
    //             break;

    //           case "postal":
    //             control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} colon={false} key={formField.fieldName} className='form-item' >
    //               <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>

    //                 <MaskedInput
    //                   tabIndex={newTabIndex}
    //                   name={formField.fieldName}
    //                   autoComplete={'off'}
    //                   size="large"
    //                   mask={"A1A 1A1"}
    //                   allowClear={formField.allowClear}
    //                   maxLength={7}
    //                   placeholder={formField.placeholder}
    //                   disabled={disabled}
    //                   onChange={e => this.changeFieldValue(formField.fieldName, e.target.value, formField.hasEvent)}
    //                 />

    //             </Form.Item>;
    //             break;

    //           case "password":
    //             control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} colon={false} key={formField.fieldName} className='form-item'>
    //               <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>

    //                 <Input.Password
    //                   tabIndex={newTabIndex}
    //                   name={formField.fieldName}
    //                   size="large"
    //                   autoComplete={'off'}
    //                   allowClear={formField.allowClear}
    //                   placeholder={formField.placeholder}
    //                   onChange={e => this.changeFieldValue(formField.fieldName, e.target.value, formField.hasEvent)}
    //                 />

    //             </Form.Item>;
    //             break;

    //           case "date":
    //             control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} colon={false} key={formField.fieldName} className='form-item' >
    //               <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>

    //                 <DatePicker
    //                   tabIndex={newTabIndex}
    //                   name={formField.fieldName}
    //                   // showAction={["focus"]}
    //                   showToday
    //                   disabled={disabled}
    //                   placeholder={formField.placeholder !== undefined && formField.placeholder !== "" ? formField.placeholder : 'dd-mmm-yyyy'}
    //                   onChange={e => this.changeFieldValue(formField.fieldName, e, formField.hasEvent)}
    //                   allowClear={formField.required === true ? false : true}
    //                   size="large" />


    //             </Form.Item>;
    //             break;

    //           case "time":
    //             control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} colon={false} key={formField.fieldName} className='form-item' >
    //               <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>

    //                 <TimePicker
    //                   use12Hours
    //                   tabIndex={newTabIndex}
    //                   name={formField.fieldName}
    //                   autoFocus={autoFocus}
    //                   defaultOpenValue={moment('10:00:00', 'h:mm A')}
    //                   format="h:mm A"
    //                   minuteStep={15}
    //                   placeholder={formField.placeholder !== undefined && formField.placeholder !== "" ? formField.placeholder : 'time'}
    //                   onChange={(time, timeString) => this.changeTimeFieldValue(formField.fieldName, time, timeString, formField.hasEvent)}
    //                   allowClear={formField.required === true ? false : true}
    //                   disabled={disabled}
    //                   size="large" />


    //             </Form.Item>;
    //             break;


    //           case "note":
    //             let noteMargin = '4px 0px';
    //             if (formField.labelName === '')
    //               noteMargin = "16px 0px";
    //             control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} colon={false} key={formField.fieldName} style={{ margin: noteMargin }} >
    //               <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>

    //                 <TextArea
    //                   name={formField.fieldName}
    //                   size="large"
    //                   rows={formField.rows ? formField.rows : 3}
    //                   maxLength={formField.valueLength}
    //                   placeholder={formField.placeholder}
    //                   disabled={disabled}
    //                   onChange={e => this.changeFieldValue(formField.fieldName, e.target.value, formField.hasEvent)}
    //                 />

    //             </Form.Item>;
    //             break;

    //           case "label":
    //             control = <Form.Item name={formField.fieldName} colon={false} key={formField.fieldName} className='form-item' >
    //               <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{formField.labelName} </label></Tooltip>

    //                 <Input
    //                   tabIndex={newTabIndex}
    //                   name={formField.fieldName}

    //                   size="large"
    //                   disabled={true}
    //                   style={{ color: 'black', fontWeight: '300' }}
    //                 />

    //             </Form.Item>;
    //             break;

    //           case "heading":
    //             control = <Form.Item colon={false} key={formField.fieldName} className='form-item' style={lastRow === 1 ? { margin: '4px 0px' } : { margin: '44px 0px 0px 0px' }} >
    //               <Label
    //                 labelType="Subtitle1" caption={formField.labelName} style={{ backgroundColor: '#B2D7ED', padding: '0px 16px', width: '100%', fontWeight: 900 }} />
    //             </Form.Item>;
    //             break;

    //           case "headingLarge":
    //             control = <Form.Item colon={false} key={formField.fieldName} style={lastRow === 1 ? { margin: '4px 0px' } : { margin: '44px 0px 0px 0px' }} >
    //               <Label
    //                 labelType="H6" caption={formField.labelName} style={{ padding: '0px 16px', width: '100%' }} />
    //             </Form.Item>;
    //             break;

    //           case "alert":
    //             control = <Form.Item colon={false} key={formField.fieldName} style={lastRow === 1 ? { margin: '4px 0px' } : { margin: '44px 0px 0px 0px' }} >
    //               <Alert message={formField.labelName} type={formField.options} />
    //             </Form.Item>;
    //             break;

    //           case "money":
    //             control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} colon={false} key={formField.fieldName} style={{ margin: '4px 16px', width: '100%' }} >
    //               <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>

    //                 <InputNumber
    //                   tabIndex={newTabIndex}
    //                   name={formField.fieldName}
    //                   size="large"
    //                   precision={2}
    //                   min={0.00}
    //                   formatter={value => `${value}`}
    //                   style={{ width: '100%' }}
    //                   placeholder={formField.placeholder}
    //                   disabled={disabled}
    //                   onChange={e => this.changeFieldValue(formField.fieldName, e, formField.hasEvent)}
    //                 />

    //             </Form.Item>;
    //             break;

    //           case "startingBalance":
    //             control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} colon={false} key={formField.fieldName} style={{ margin: '4px 0px', width: '100%' }} >
    //               <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{formField.labelName} {formField.required === true ? '*' : ''}</label></Tooltip>

    //                 <InputNumber
    //                   tabIndex={newTabIndex}
    //                   name={formField.fieldName}
    //                   autoFocus={autoFocus}
    //                   size="large"
    //                   precision={2}
    //                   min={-1000}
    //                   formatter={value => `${value}`}
    //                   style={{ width: '100%' }}
    //                   placeholder={formField.placeholder}
    //                   disabled={disabled}
    //                   onChange={e => this.changeFieldValue(formField.fieldName, e, formField.hasEvent)}
    //                 />

    //             </Form.Item>;
    //             break;

    //           case "checkbox":
    //             let checkboxMargin = '40px 0px 0px 16px';
    //             if (formField.checkboxOnOwnLine === true)
    //               checkboxMargin = "8px 16px";

    //             control = <Form.Item name={formField.fieldName} valuePropName='checked' colon={false} key={formField.fieldName} style={{ margin: checkboxMargin }}>
    //               {formField.placeholder ? <React.Fragment><Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{formField.placeholder}</label></Tooltip><br /></React.Fragment> : null}

    //                  <Checkbox
    //                   tabIndex={newTabIndex}
    //                   style={{ fontWeight: 400, fontSize: '16px' }}
    //                   disabled={disabled}
    //                   onChange={e => this.changeFieldValue(formField.fieldName, e.target.checked, formField.hasEvent)}
    //                 >
    //                   <Tooltip title={tooltip}> {formField.labelName}</Tooltip>
    //                 </Checkbox>

    //             </Form.Item>;
    //             break;

    //           case "checkbox.group":

    //             let colWidth = 24;
    //             if (formField.valueLength === 2)
    //               colWidth = 12;
    //             if (formField.valueLength === 3)
    //               colWidth = 8;
    //             if (formField.valueLength === 4)
    //               colWidth = 6;
    //             if (isMobileOnly)
    //               colWidth = 24;

    //             if (formField.options !== undefined && formField.options !== '') {

    //               let checkBoxList = formField.options;
    //               checkBoxList.forEach(function (data) {
    //                 data['label'] = data['id'];
    //                 delete data['id'];
    //               });



    //               control = <Form.Item colon={false} key={formField.fieldName} className='form-item' style={{ fontWeight: 500, display: 'block' }}>
    //                 <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip><br />
    //                 <Tooltip title={tooltip}>
    //                   <Checkbox.Group name={formField.fieldName} onChange={(value) => this.changeSelectMultipleValue(formField.fieldName, value, formField.hasEvent)}>
    //                     {checkBoxList.map(row =>
    //                     (
    //                       <Col span={colWidth}>
    //                         <Checkbox value={row['value']}> {row['label']}   </Checkbox>
    //                       </Col>
    //                     ))
    //                     }
    //                   </Checkbox.Group>
    //                 </Tooltip>
    //               </Form.Item>;
    //             }
    //             else {

    //               let checkBoxData = formField.dataList;
    //               checkBoxData.forEach(function (data) {
    //                 data['label'] = data['listValue'];
    //                 data['value'] = data['listValue'];
    //               });


    //               control = <Form.Item name={formField.fieldName} valuePropName='value' colon={false} key={formField.fieldName} className='form-item' style={{ fontWeight: 500, display: 'block' }}>
    //                 <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip><br />

    //                   <Tooltip title={tooltip}>
    //                     <Checkbox.Group name={formField.fieldName} onChange={(value) => this.changeSelectMultipleValue(formField.fieldName, value, formField.hasEvent)}>
    //                       {checkBoxData.map(row =>
    //                       (
    //                         <Col span={colWidth} key={row['value']}>
    //                           <Checkbox style={{ fontSize: '14px' }} value={row['value']}> {row['label']}   </Checkbox>
    //                         </Col>
    //                       ))
    //                       }
    //                     </Checkbox.Group>
    //                   </Tooltip>

    //               </Form.Item>;

    //             }


    //             break;

    //           case "select":

    //             if (formField.options !== undefined) {
    //               selectOptions = formField.options;
    //               control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} valuePropName='value' colon={false} key={formField.fieldName} className='form-item' style={{ fontWeight: 500 }}>
    //                 <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }}>{currentLabelName}</label></Tooltip>

    //                   <Select size="large"
    //                     tabIndex={newTabIndex}
    //                     placeholder={formField.placeholder}
    //                     allowClear={true}
    //                     // showAction={["focus"]}
    //                     autoComplete={'off'}
    //                     showSearch
    //                     optionFilterProp="children"
    //                     disabled={disabled}
    //                     filterOption={(input, option) =>
    //                       option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //                     }
    //                     onSearch={this.onSearch}
    //                     onChange={(key, value) => this.changeSelectValue(formField.fieldName, value, formField.hasEvent)}>
    //                     {Object.entries(selectOptions).map(([key, value]) =>
    //                     (
    //                       <Option key={key} value={value} >
    //                         {value}
    //                       </Option>
    //                     ))
    //                     }
    //                   </Select>
    //               </Form.Item>;
    //             }
    //             else {
    //               selectOptions = formField.dataList;

    //               control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} valuePropName='value' colon={false} key={formField.fieldName} className='form-item'>
    //                 <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>

    //                   <Select size="large"
    //                     tabIndex={newTabIndex}
    //                     placeholder={formField.placeholder}
    //                     allowClear={true}
    //                     // showAction={["focus"]}
    //                     showSearch
    //                     autoComplete={'off'}
    //                     optionFilterProp="children"
    //                     disabled={disabled}
    //                     filterOption={(input, option) =>
    //                       option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //                     }
    //                     onSearch={this.onSearch}
    //                     onChange={(key, value) => this.changeSelectValue(formField.fieldName, value, formField.hasEvent)}
    //                   >
    //                     {selectOptions.map(row =>
    //                     (
    //                       <Option key={row[formField.dataKey]} value={formField.dataValueNotKey === true ? row[formField.dataValue] : row[formField.dataKey]} >
    //                         {row[formField.dataValue]}
    //                       </Option>
    //                     ))
    //                     }
    //                   </Select >
    //               </Form.Item>;

    //             }
    //             break;

    //           case "selectAdd":

    //             if (formField.options !== undefined && formField.options !== '') {
    //               selectOptions = formField.options;

    //               control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} valuePropName='value' colon={false} key={formField.fieldName} className='form-item' style={{ fontWeight: 500 }}>
    //                 <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }}>{currentLabelName}</label></Tooltip>

    //                   <AutoComplete size="large"
    //                     tabIndex={newTabIndex}
    //                     autoFocus={autoFocus}
    //                     autoComplete="off"
    //                     allowClear={true}
    //                     placeholder={formField.placeholder}
    //                     disabled={disabled}
    //                     options={selectAddOptions}
    //                     onChange={(value) => this.changeFieldValue(formField.fieldName, value, formField.hasEvent)}
    //                     onSearch={(searchText) => {


    //                       this.setSelectAddOptions(
    //                         !searchText ? [] : [selectOptions].includes(searchText)
    //                       );

    //                     }}
    //                   >
    //                   </AutoComplete >
    //               </Form.Item>;
    //             }
    //             else {
    //               selectOptions = formField.dataList;


    //               control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} valuePropName='value' colon={false} key={formField.fieldName} className='form-item'>
    //                 <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>

    //                   <AutoComplete size="large"
    //                     tabIndex={newTabIndex}
    //                     autoFocus={autoFocus}
    //                     autoComplete="off"
    //                     allowClear={true}
    //                     placeholder={formField.placeholder}
    //                     disabled={disabled}
    //                     options={selectAddOptions}
    //                     onChange={(value) => this.changeFieldValue(formField.fieldName, value, formField.hasEvent)}
    //                     onSearch={(searchText) => {
    //                       if (selectOptions)
    //                         this.setSelectAddOptions(
    //                           !searchText ? [] : selectOptions.filter(o => o[formField.dataValue].includes(searchText))
    //                         );

    //                     }}
    //                   >
    //                   </AutoComplete >



    //               </Form.Item>;
    //             };

    //             break;


    //           case "selectMultiple":
    //             let selectMultipleOptions = null;
    //             selectMultipleOptions = formField.dataList;

    //             control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} valuePropName='value' colon={false} key={formField.fieldName} className='form-item'>
    //               <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>

    //                 <Select
    //                   tabIndex={newTabIndex}
    //                   size="large"
    //                   mode="multiple"
    //                   disabled={disabled}
    //                   // showAction={["focus"]}
    //                   placeholder={formField.placeholder}
    //                   allowClear={true}
    //                   onChange={(key, value) => this.changeSelectMultipleValue(formField.fieldName, value, formField.hasEvent)}
    //                 >
    //                   {selectMultipleOptions !== undefined ?
    //                     selectMultipleOptions.map(row =>
    //                     (
    //                       <Option key={row[formField.dataKey]} value={formField.dataValueNotKey === true ? row[formField.dataValue] : row[formField.dataKey]} >
    //                         {row[formField.dataValue]}
    //                       </Option>
    //                     ))
    //                     : null}
    //                 </Select >
    //             </Form.Item>;


    //             break;

    //           case "radio":
    //             let radioOptions = null;

    //             if (formField.options !== undefined) {
    //               radioOptions = formField.options;
    //               control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} valuePropName='value' colon={false} key={formField.fieldName} className='form-item' style={{ fontWeight: 500 }}>
    //                 <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} >{currentLabelName}</label></Tooltip>

    //                   <Radio.Group
    //                     tabIndex={newTabIndex}
    //                     disabled={disabled}
    //                     onChange={(e) => this.changeRadioValue(formField.fieldName, e.target.value, formField.hasEvent)}
    //                   >
    //                     {Object.entries(radioOptions).map(([key, value]) =>
    //                     (
    //                       <Radio value={value} key={key} style={formField.radioStyle} >
    //                         {value}
    //                       </Radio >
    //                     ))
    //                     }
    //                   </Radio.Group >
    //               </Form.Item>;
    //             }
    //             else {
    //               radioOptions = formField.dataList;

    //               control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} valuePropName='value' colon={false} key={formField.fieldName} className='form-item'>
    //                 <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>

    //                   <Radio.Group
    //                     tabIndex={newTabIndex}
    //                     disabled={disabled}
    //                     onChange={(e) => this.changeRadioValue(formField.fieldName, e.target.value, formField.hasEvent)}
    //                   >
    //                     {radioOptions.map(row =>
    //                     (
    //                       <Radio key={row[formField.dataKey]} style={formField.radioStyle} value={formField.dataValueNotKey === true ? row[formField.dataValue] : row[formField.dataKey]} >
    //                         {row[formField.dataValue]}
    //                       </Radio>
    //                     ))
    //                     }
    //                   </Radio.Group >
    //               </Form.Item>;

    //             }
    //             break;


    //           case "radio.button":
    //             let radioButtonOptions = null;

    //             if (formField.options !== undefined) {
    //               radioButtonOptions = formField.options;
    //               control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} valuePropName='value' colon={false} key={formField.fieldName} className='form-item' style={{ fontWeight: 500 }}>
    //                 <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} >{formField.labelName} {formField.required === true ? '*' : ''}</label></Tooltip>

    //                   <Radio.Group
    //                     buttonStyle={formField.radioStyle}
    //                     tabIndex={newTabIndex}
    //                     onChange={(e) => this.changeRadioValue(formField.fieldName, e.target.value, formField.hasEvent)}
    //                   >
    //                     {Object.entries(radioButtonOptions).map(([key, value]) =>
    //                     (
    //                       <Radio.Button value={value} key={key}  >
    //                         {value}
    //                       </Radio.Button >
    //                     ))
    //                     }
    //                   </Radio.Group >
    //               </Form.Item>;
    //             }
    //             else {
    //               radioButtonOptions = formField.dataList;

    //               control = <Form.Item name={formField.fieldName} rules={[{ required: formField.required, message: formField.requiredText }]} valuePropName='value' colon={false} key={formField.fieldName} className='form-item'>
    //                 <Tooltip title={tooltip}>  <label htmlFor={formField.fieldName} className='form-label' style={{ fontSize: hasLargeLabel ? '20px' : '14px' }} >{currentLabelName}</label></Tooltip>

    //                   <Radio.Group
    //                     buttonStyle={formField.radioStyle}
    //                     tabIndex={newTabIndex}
    //                     disabled={disabled}
    //                     onChange={(e) => this.changeRadioValue(formField.fieldName, e.target.value, formField.hasEvent)}
    //                   >
    //                     {radioButtonOptions.map(row =>
    //                     (
    //                       <Radio.Button key={row[formField.dataKey]} value={formField.dataValueNotKey === true ? row[formField.dataValue] : row[formField.dataKey]} >
    //                         {row[formField.dataValue]}
    //                       </Radio.Button>
    //                     ))
    //                     }
    //                   </Radio.Group >
    //               </Form.Item>;

    //             }
    //             break;

    //           case "button":
    //             control =
    //               <Tooltip title={tooltip}><Button
    //                 name={formField.fieldName}
    //                 tabIndex={newTabIndex}
    //                 block
    //                 style={{ marginTop: '24px', width: '100%' }}
    //                 type={formField.buttonStyle}
    //                 disabled={disabled}
    //                 onClick={e => this.clickButton(formField.fieldName)}
    //               >
    //                 {formField.labelName}
    //               </Button></Tooltip>

    //             break;

    //           case "icon":
    //             if (formField.defaultValue === true)
    //               control =
    //                 <Tooltip title={formField.labelName} >
    //                   <Icon
    //                     name={formField.fieldName}
    //                     tabIndex={newTabIndex}
    //                     type={formField.valueType}
    //                     theme="filled"
    //                     disabled={disabled}
    //                     style={{ marginTop: '42px', marginLeft: '8px', width: '100%', fontSize: formField.valueLength }}
    //                     onClick={e => this.clickIcon(formField.fieldName)}
    //                     aria-label={formField.labelName}
    //                   />
    //                 </Tooltip>;

    //             break;

    //           case "customControl1":
    //             control = customControl1;
    //             break;
    //           case "customControl2":
    //             control = customControl2;
    //             break;
    //           case "customControl3":
    //             control = customControl3;
    //             break;

    //           case "customControl4":
    //             control = customControl4;
    //             break;

    //           default:
    //         };

    //         return (
    //           <React.Fragment key={"item" + formField.fieldName}  >
    //             {newRow}
    //             <Col span={formField.colWidth} offset={formField.colOffset} >
    //               {control}
    //             </Col>
    //           </React.Fragment>)

    //       }
    //       else {
    //         return null;
    //       }
    //     }

    //     )
    //   };

    // }

    let ShowRequiredFields = null;
    if (hasRequiredFields === true)
      ShowRequiredFields = <p style={{ margin: '16px 0px 0px 16px', fontWeight: 800, display: 'inline-block' }}>* Required Fields</p>;

    return (

      <React.Fragment  {...rest} >
        <Form form={form} onFinish={onSubmit} style={{ margin: '0px' }}>
          {formTitle}
          <Form.Item name={'dirty'} key={'dirty2'} style={{ margin: '0px' }} hidden={true} >
            <Input name={'dirty'} />
          </Form.Item>
          <Row gutter={16}>
            {/* <Col xs={24} sm={24} md={24} lg={this.props.columns === 1 ? 24 : 12}> */}
            {FormFields}
            {/* </Col> */}
            {/* {this.props.columns === 2 ?
              <Col xs={24} sm={24} md={24} lg={this.props.columns === 1 ? 24 : 12}  >
                {FormFields2}
              </Col>
              : null} */}

            <Divider style={{ height: '0px', margin: '0px', padding: '0px' }} />
            <Col xs={24} sm={24} md={24} lg={24}>
              {ShowRequiredFields}
              {saveButton}
              {deleteButton}
              {printButton}
              {primaryButton}
            </Col>

            <Divider style={{ height: '0px', margin: '0px', padding: '0px' }} />
          </Row>
        </Form>
        {Modal}
      </React.Fragment>


    )
  }
}
