import React, { useEffect, useRef, useState } from 'react';
import Label from '../../elements/Label';
import { Row } from 'antd';
import AppBar from '../AppBar';
import './index.css';

export default function AppLayout({ children }) {
    const containerRef = useRef(null);
    const [atBottom, setAtBottom] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const { scrollTop, clientHeight, scrollHeight } = containerRef.current;

            let newAtBottom = scrollTop + clientHeight >= scrollHeight;
            if (atBottom !== newAtBottom) {
                setAtBottom(newAtBottom);
            }
        };

        const containerElement = containerRef.current;
        containerElement.addEventListener('scroll', handleScroll);

        // Cleanup: remove event listener when the component is unmounted
        return () => {
            containerElement.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty dependency array means this effect runs once when the component mounts
 
    return (
        <div ref={containerRef} className='app-layout'>
            <AppBar />
            <div id='pageContent' className={'page-content'}> 
                {children}
            </div> 
            {atBottom || 
                <Row className='footer'>
                    <Label className='footer-text' type='Body2' caption={'© ' + (new Date().getFullYear()) + ' Better World Software Inc.'} />
                </Row>}
        </div>
    )
}