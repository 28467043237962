import React, { Component } from 'react';
import BulletIcon from '@mui/icons-material/CheckCircle';

export default class Label extends Component {
  render() {
    const {
      caption,
      style,
      colon,
      labelType,
      ...rest
    } = this.props;


    let typeFontSize = '14px';
    let typeFontWeight = 500;
    let bullet = false;

    switch (labelType) {
      case "H1":
        typeFontSize = '96px';
        typeFontWeight = 300;
        break;
      case "H2":
        typeFontSize = '60px';
        typeFontWeight = 300;
        break;
      case "H3":
        typeFontSize = '48px';
        break;
      case "H4":
        typeFontSize = '34px';
        break;
      case "H5":
        typeFontSize = '24px';
        break;
      case "H6":
        typeFontSize = '20px';
        typeFontWeight = 700;
        break;
      case "Subtitle1":
        typeFontSize = '16px';
        break;
      case "Subtitle2":
        typeFontWeight = 700;
        break;
      case "Body1":
        typeFontSize = '16px';
        break;
      case "Body2":
        typeFontSize = '14px';
        typeFontWeight = 300;
        break;
      case "Report":
        typeFontSize = '12px';
        typeFontWeight = 400;
        break;
      case "ReportLarge":
        typeFontSize = '14px';
        typeFontWeight = 400;
        break;
      case "ReportHeading":
        typeFontSize = '18px';
        typeFontWeight = 500;
        break;
      case "ReportItem":
        typeFontSize = '12px';
        typeFontWeight = 300;
        break;

      case "LabelLarge":
        typeFontSize = '24px';
        typeFontWeight = 500;
        break;
      case "LabelMedium":
        typeFontSize = '20px';
        typeFontWeight = 500;
        break;
      case "Mini":
        typeFontSize = '12px';
        break;
      case "Bullet":
        typeFontSize = '16px';
        typeFontWeight = 400;
        bullet = true;
        break;

      default:

    }


    return (
      bullet === true ?
        <div style={{ display: "inline" }}>
          <BulletIcon style={{ fontSize: '24px', padding: '0px', marginRight: '8px', paddingTop: '8px', display: "inline" }} />
          <div style={{ display: "inline", fontSize: typeFontSize, fontWeight: typeFontWeight, ...style }}  {...rest} >
            {caption}{colon ? ":" : ""}
          </div>
        </div>
        :
        <div style={{ display: "inline-block", fontSize: typeFontSize, fontWeight: typeFontWeight, ...style }}  {...rest} >
          {caption}{colon ? ":" : ""}
        </div>

    )
  }
}
