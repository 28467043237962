import React, { createRef } from 'react';
import BetterLogo from '../../assets/images/BetterIcon-Large.png';
import SettingsIcon from '@mui/icons-material/Settings';
import { Button } from 'antd';
import { Divider, IconButton, Toolbar, Typography, Drawer, AppBar } from '@mui/material';
import TermsModal from '../../Modals/Terms'
import AboutModal from '../../Modals/About';
import headerPic1 from '../../assets/images/BetterLogoR.png';
import headerPic2 from '../../assets/images/BetterLogoR.png';
import headerPic3 from '../../assets/images/BetterLogoR.png';

export default function MobileAppBar(props) {
    const termsRef = createRef();
    const aboutRef = createRef();

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    let menuRedirect = null;

    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [side]: open });
    };

    let headerImg = '';

    switch (props.imageNumber) {
        case 0:
            headerImg = headerPic1;
            break;
        case 1:
            headerImg = headerPic2;
            break;
        case 2:
            headerImg = headerPic3;
            break;
        default:
            headerImg = headerPic1;
    }


    const sideList = side => {
        return (
            <div className='list'
                role="presentation"
                onClick={toggleDrawer(side, false)}
                onKeyDown={toggleDrawer(side, false)}>
                <div className='header-image'>
                  <img src={headerImg} alt='welcoming' style={{ maxHeight:'100px' }} />
                </div>
                <br />  <br />
                <br />
                <Button type='link' onClick={() => aboutRef?.current?.toggleOpen(true)} >About Us</Button><br/>
                <Button type='link' onClick={() => termsRef?.current?.toggleOpen(true)} >Terms of Service</Button>

                <br />  <br />


                <Divider />
                <div className='copy-right'>
                  <p>©  {(new Date().getFullYear())}</p>
                  <p>Better World Software Inc.</p>
                </div>
            </div>
        )
    };

    const renderMenu = (
        <Drawer anchor="right" open={state.right} onClose={toggleDrawer('right', false)} style={{ zIndex: 110 }}>
            {sideList('right')}
        </Drawer>
    );

    return (
        <div className="grow" >
            <AppBar style={{zIndex: 100}}>
                {menuRedirect}
                <Toolbar className='toolbar'>
                    <div className='logo-col'>
                        <img src={BetterLogo} alt='The Better App' className='logo' />
                    </div>
                    <Typography className='page-title' variant="h6" noWrap>
                        Community Programs
                    </Typography>
                    <IconButton
                        edge="start"
                        className='menu-button'
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer('right', true)}
                    >
                        <SettingsIcon />
                    </IconButton>
                    {renderMenu}            
                </Toolbar>
            </AppBar>
            <AboutModal ref={aboutRef}/>
            <TermsModal ref={termsRef}/>
        </div>
    );
}


